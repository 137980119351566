import { getAxiosInterceptor } from '@keyops-cep/api-client';
import axios from 'axios';

import { getEnvironmentVariables } from '../utils/functions/env-utils';
import {
  getAccessToken,
  getRefreshToken,
  removeTokens,
} from '../utils/get-tokens';
import { LOGIN } from '../utils/internal-routes.const';

import {
  AUTH_ENDPOINTS,
  AZURE_LOGIN_ENDPOINT,
  AZURE_SIGNUP_ENDPOINT,
  GOOGLE_LOGIN_ENDPOINT,
  HEADERS,
  LOGOUT_ENDPOINT,
  REFRESH_TOKEN_ENDPOINT,
} from './api-endpoint.const';
import { setNewTokens } from './auth.functions';
const { REACT_APP_API_URL } = getEnvironmentVariables();

interface AxiosApiCallPropTypes {
  method: string;
  endpoint: string;
  params?: Record<string, string>;
  data?: unknown;
  extraData?: Record<string, string | null>;
}

export const api = async ({
  method,
  endpoint,
  params,
  data,
  extraData,
}: AxiosApiCallPropTypes) => {
  if (AUTH_ENDPOINTS.includes(endpoint)) {
    switch (endpoint) {
      case GOOGLE_LOGIN_ENDPOINT:
      case AZURE_SIGNUP_ENDPOINT:
      case AZURE_LOGIN_ENDPOINT:
        HEADERS.Authorization = `Bearer ${extraData?.idToken}`;
        break;
      case REFRESH_TOKEN_ENDPOINT:
        HEADERS['x-refresh-token'] = getRefreshToken();
        break;
      case LOGOUT_ENDPOINT:
        HEADERS.Authorization = `Bearer ${getAccessToken()}`;
        HEADERS['x-refresh-token'] = getRefreshToken();
        break;
      default:
        break;
    }
  } else {
    const accessToken = localStorage.getItem('KeyOps_AccessToken');
    // if there is an access token in local storage, use it
    // if not, it may not be needed, or we'll get a 401, which should
    // be handled by the axios interceptor
    if (accessToken) {
      HEADERS.Authorization = `Bearer ${localStorage.getItem(
        'KeyOps_AccessToken'
      )}`;
    }
  }

  const request = {
    headers: HEADERS,
    baseURL: REACT_APP_API_URL,
    method,
    withCredentials: true,
    url: endpoint,
    params,
    data,
  };

  console.log('******* request *******');
  console.log(request);

  return await axios(request);
};

// Response Interceptor to globally handle errors
const axiosInterceptor = getAxiosInterceptor(
  setNewTokens,
  removeTokens,
  LOGIN,
  axios
);
axios.interceptors.response.use(
  axiosInterceptor.responseHandler,
  axiosInterceptor.errorHandler
);
