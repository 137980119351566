import { removeTokens, setTokens } from '../utils/get-tokens';

import {
  AZURE_LOGIN_ENDPOINT,
  AZURE_SIGNUP_ENDPOINT,
  EMAIL_PASSWORD_LOGIN_ENDPOINT,
  EMAIL_PASSWORD_SIGNUP_ENDPOINT,
  FORGOT_PASSWORD,
  GOOGLE_LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  METHOD_TYPES,
  REFRESH_TOKEN_ENDPOINT,
  RESET_PASSWORD,
  VALIDATE_RESET_PASSWORD_TOKEN,
  VALIDATE_SIGNUP_TOKEN,
} from './api-endpoint.const';
import { api } from './axiosApi';

export const azureLoginApiCall = async ({
  idToken,
}: {
  idToken: string | null;
}) => {
  const response = await api({
    method: METHOD_TYPES.GET,
    endpoint: AZURE_LOGIN_ENDPOINT,
    extraData: {
      idToken,
    },
  });
  if (response?.status === 200) {
    setTokens(response.data.accessToken, response.data.refreshToken);
  }
  return response;
};

export const googleLoginApiCall = async ({
  idToken,
}: {
  idToken: string | null;
}) => {
  const response = await api({
    method: METHOD_TYPES.GET,
    endpoint: GOOGLE_LOGIN_ENDPOINT,
    extraData: {
      idToken,
    },
  });
  if (response?.status === 200) {
    setTokens(response.data.accessToken, response.data.refreshToken);
  }
  return response;
};

export const emailPasswordLoginApiCall = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const response = await api({
    method: METHOD_TYPES.POST,
    endpoint: EMAIL_PASSWORD_LOGIN_ENDPOINT,
    data: {
      email,
      password,
    },
  });
  if (response?.status === 201) {
    setTokens(response.data.accessToken, response.data.refreshToken);
  }
  return response;
};

export const azureSignupApiCall = async ({
  idToken,
  signupToken,
}: {
  idToken: string | null;
  signupToken: string;
}) => {
  const response = await api({
    method: METHOD_TYPES.POST,
    endpoint: AZURE_SIGNUP_ENDPOINT,
    params: {
      signupToken,
    },
    extraData: {
      idToken: idToken,
    },
  });
  if (response?.status === 201) {
    setTokens(response.data.accessToken, response.data.refreshToken);
  }
  return response;
};

export const emailPasswordSignupApiCall = async ({
  signupToken,
  password,
}: {
  signupToken: string;
  password: string;
}) => {
  const response = await api({
    method: METHOD_TYPES.POST,
    endpoint: EMAIL_PASSWORD_SIGNUP_ENDPOINT,
    params: {
      signupToken,
    },
    data: {
      password,
    },
  });
  if (response?.status === 201) {
    setTokens(response.data.accessToken, response.data.refreshToken);
  }
  return response;
};

export const forgotPasswordApiCall = async ({ email }: { email: string }) => {
  const response = await api({
    method: METHOD_TYPES.POST,
    endpoint: FORGOT_PASSWORD,
    data: {
      email,
    },
  });
  return response;
};

export const resetPasswordApiCall = async ({
  resetPasswordToken,
  password,
}: {
  resetPasswordToken: string;
  password: string;
}) => {
  const response = await api({
    method: METHOD_TYPES.POST,
    endpoint: RESET_PASSWORD,
    extraData: {
      resetPasswordToken,
    },
    data: {
      password,
    },
  });
  return response;
};

export const validateSignupToken = async ({
  signupToken,
}: {
  signupToken: string;
}) => {
  const response = await api({
    method: METHOD_TYPES.GET,
    endpoint: VALIDATE_SIGNUP_TOKEN,
    params: {
      signupToken,
    },
  });
  return response;
};

export const validateResetPasswordToken = async ({
  resetPasswordToken,
}: {
  resetPasswordToken: string;
}) => {
  const response = await api({
    method: METHOD_TYPES.GET,
    endpoint: VALIDATE_RESET_PASSWORD_TOKEN,
    params: {
      resetPasswordToken,
    },
  });
  return response;
};

export const logoutApiCall = async () => {
  return await api({
    method: METHOD_TYPES.POST,
    endpoint: LOGOUT_ENDPOINT,
  });
};

export async function setNewTokens() {
  try {
    const response = await getNewTokens();
    if (response?.status === 201) {
      setTokens(response.data.accessToken, response.data.refreshToken);
      return response;
    }
  } catch (error) {
    removeTokens();
    throw error;
  }
}

export const getNewTokens = async () => {
  return await api({
    method: METHOD_TYPES.POST,
    endpoint: REFRESH_TOKEN_ENDPOINT,
  });
};
