////////////// LOGIN //////////////////
export const AZURE_LOGIN_ENDPOINT = '/auth/azure-login';
export const GOOGLE_LOGIN_ENDPOINT = '/auth/google-login';
export const EMAIL_PASSWORD_LOGIN_ENDPOINT = '/auth/email-password-login';
export const REFRESH_TOKEN_ENDPOINT = '/auth/refresh-tokens';
export const LOGOUT_ENDPOINT = '/auth/logout';
export const AZURE_SIGNUP_ENDPOINT = '/auth/azure-signup';
export const EMAIL_PASSWORD_SIGNUP_ENDPOINT = '/auth/email-password-signup';
export const FORGOT_PASSWORD = '/auth/password-reset-initiate';
export const RESET_PASSWORD = '/auth/password-reset-complete';
export const VALIDATE_RESET_PASSWORD_TOKEN =
  '/auth/validate-reset-password-token';
export const VALIDATE_SIGNUP_TOKEN = '/auth/validate-signup-token';

export const AUTH_ENDPOINTS: string[] = [
  AZURE_LOGIN_ENDPOINT,
  REFRESH_TOKEN_ENDPOINT,
  LOGOUT_ENDPOINT,
  AZURE_SIGNUP_ENDPOINT,
  GOOGLE_LOGIN_ENDPOINT,
  EMAIL_PASSWORD_SIGNUP_ENDPOINT,
  EMAIL_PASSWORD_LOGIN_ENDPOINT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VALIDATE_RESET_PASSWORD_TOKEN,
  VALIDATE_SIGNUP_TOKEN,
];

////////////// ENGAGEMENTS //////////////////
export const GET_ENGAGEMENTS_ENDPOINT = '/engagements';
export const GET_ENGAGEMENT_BY_ID_ENDPOINT = '/engagements/:engagementId';
export const GET_ENGAGEMENT_DETAILS_BY_ID_ENDPOINT =
  '/engagements/:engagementId/details';

export const GET_AI_SESSION_ENDPOINT = '/engagements/:engagementId/ai-session';
export const POST_AI_SESSION_ENDPOINT = '/engagements/:engagementId/ai-session';

////////////// USERS //////////////////
export const GET_USER_PROFILE_ENDPOINT = '/auth/user-profile';

////////////// TENANTS //////////////////
export const GET_TENANT_ENDPOINT = '/tenants/:tenantId';

////////////// CHAT //////////////////
export const GET_AI_SESSION_RUN_STATUS_ENDPOINT =
  '/engage-ai-session/:sessionId/run-status/:runId';

////////////// HEADERS //////////////////
export const HEADERS: Record<string, string | null> = {
  'Content-Type': 'application/json',
};

////////////// HTTP METHODS //////////////////
export const METHOD_TYPES: Record<string, string> = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};
