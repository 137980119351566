import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Box, Button, Center, Text, Tooltip } from '@chakra-ui/react';
import { Engagement } from '@keyops-cep/api-client';
import { createColumnHelper, FilterFn } from '@tanstack/react-table';

import ColumnHeader from '../../../components/TableComponents/ColumnHeader';
import { ANALYTICS_EVENT_NAME } from '../../../utils/constants/analytics-constants';
import {
  ENGAGEMENT_BADGE_COLOR_SCHEME,
  ENGAGEMENT_STATUSES,
  EngagementStatusValues,
} from '../../../utils/dto/engagement.dto';
import { dateFormatter, getUrl } from '../../../utils/functions/common-utils';
import i18n from '../../../utils/i18n';
import {
  ENGAGEMENT_DETAILS,
  ENGAGEMENT_RESULTS_TAB,
} from '../../../utils/internal-routes.const';

// Build table columns logic
export interface EngagementsTableColumnsDto extends Engagement {
  respondents?: string;
  action?: string;
}

const columnHelper = createColumnHelper<EngagementsTableColumnsDto>();

const MAX_PROJECT_NAME_WIDTH = 50;

const StatusBadge = ({ status }: { status: EngagementStatusValues }) => {
  const isInsightsAvailable = status === ENGAGEMENT_STATUSES.INSIGHTS_AVAILABLE;
  return (
    <>
      {isInsightsAvailable && (
        <>
          <Badge
            colorScheme={
              ENGAGEMENT_BADGE_COLOR_SCHEME[
                ENGAGEMENT_STATUSES.RAW_DATA_AVAILABLE
              ]
            }
          >
            {ENGAGEMENT_STATUSES.RAW_DATA_AVAILABLE}
          </Badge>
          <br />
        </>
      )}
      <Badge
        colorScheme={ENGAGEMENT_BADGE_COLOR_SCHEME[status]}
        mt={isInsightsAvailable ? 1 : 0}
      >
        {status}
      </Badge>
    </>
  );
};

const filterTitleColumn: FilterFn<EngagementsTableColumnsDto> = (
  rows,
  id,
  filterValue
) => {
  if (!filterValue) return true;

  return (
    !!rows.original['name']
      ?.toLowerCase()
      ?.includes(filterValue.toLowerCase()) ||
    !!rows.original['displayTitle']
      ?.toLowerCase()
      ?.includes(filterValue.toLowerCase())
  );
};

const useEngagementsTableColumns = () => {
  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        header: ({ column }) => (
          <ColumnHeader headerText={'Title'} sorted={column.getIsSorted()} />
        ),
        filterFn: filterTitleColumn,
        cell: (props) => {
          const displayTitle = props.row.original.displayTitle;
          const shortDisplayTitle = displayTitle
            ? displayTitle.length > MAX_PROJECT_NAME_WIDTH
              ? displayTitle.substring(0, MAX_PROJECT_NAME_WIDTH) + '...'
              : displayTitle
            : '';

          return (
            <Tooltip
              label={displayTitle}
              isDisabled={displayTitle === shortDisplayTitle}
              bg="white"
              color="black"
              maxW="none"
              fontSize="xs"
              p={1}
            >
              <Box>
                <Text isTruncated>{props.getValue()}</Text>
                <Text isTruncated fontSize={'12px'} mt={1}>
                  {shortDisplayTitle}
                </Text>
              </Box>
            </Tooltip>
          );
        },
      }),
      columnHelper.accessor('status', {
        header: ({ column }) => (
          <ColumnHeader headerText={'Status'} sorted={column.getIsSorted()} />
        ),
        cell: (props) => <StatusBadge status={props.getValue()} />,
      }),
      columnHelper.accessor('startDate', {
        header: ({ column }) => (
          <ColumnHeader
            headerText={'Start Date'}
            sorted={column.getIsSorted()}
          />
        ),
        cell: (props) => dateFormatter(props.getValue()),
      }),
      columnHelper.accessor('endDate', {
        header: ({ column }) => (
          <ColumnHeader headerText={'Closed'} sorted={column.getIsSorted()} />
        ),
        cell: (props) => dateFormatter(props.getValue()),
      }),
      columnHelper.accessor('respondents', {
        header: () => (
          <Text align="right" pr={10}>
            Respondents
          </Text>
        ),
        enableSorting: false,
        cell: (props) => (
          <Text align="right" pr={10}>
            {props.row.original.responseCount}/
            {props.row.original.responseCountTarget}
          </Text>
        ),
      }),
      columnHelper.accessor('action', {
        header: () => <Center>Action</Center>,
        enableSorting: false,
        cell: (props) => {
          if (
            props.row.original.status ===
              ENGAGEMENT_STATUSES.RAW_DATA_AVAILABLE ||
            props.row.original.status === ENGAGEMENT_STATUSES.INSIGHTS_AVAILABLE
          )
            return (
              <Center>
                <Button
                  colorScheme="blue"
                  variant="ghost"
                  _hover={{ textDecoration: 'underline' }}
                  fontWeight={500}
                  onClick={() => {
                    window.analytics.track(
                      ANALYTICS_EVENT_NAME.DASHBOARD
                        .ENGAGEMENT_LIST_VIEW_BUTTON_CLICK,
                      {
                        engagementId: props.row.original.id,
                        name: props.row.original.name,
                        status: props.row.original.status,
                      }
                    );
                    navigate(
                      `${getUrl(ENGAGEMENT_DETAILS, {
                        engagementId: props.row.original.id,
                      })}/${ENGAGEMENT_RESULTS_TAB}`
                    );
                  }}
                >
                  {i18n.t('common.view')}
                </Button>
              </Center>
            );
          return <></>;
        },
      }),
    ],
    [navigate]
  );

  return columns;
};

export default useEngagementsTableColumns;
