import React from 'react';
import { Heading, Text } from '@chakra-ui/react';

import Loading from '../../components/Loading';
import { useEngagements } from '../../hooks/useEngagements';
import useUserProfileData from '../../hooks/useUserProfileData';
import i18n from '../../utils/i18n';

import EngagementsTable from './components/EngagementsTable';

const Engagements: React.FC = () => {
  const { data: engagements, isLoading, isError } = useEngagements();
  const { data: userProfileData } = useUserProfileData();

  const title = userProfileData?.tenant?.name
    ? i18n.t('engagements.title', {
        tenantName: userProfileData?.tenant?.name,
      })
    : i18n.t('engagements.welcome');

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Heading as="h1" fontSize={24} lineHeight={'32px'}>
        {title}
      </Heading>
      <Text fontSize="md" fontWeight={400} pt={2}>
        {i18n.t('engagements.subTitle')}
      </Text>
      <Heading as="h2" mt={8} fontSize={20} lineHeight={6}>
        {i18n.t('engagements.tableSection.title')}
      </Heading>
      {!isError && <EngagementsTable data={engagements || []} />}
    </>
  );
};

export default Engagements;
